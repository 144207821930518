import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import '../../utils/normalize.css';
import '../../utils/css/screen.css';

export default class GridTool extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false,
      firstName: '',
      lastName: '',
      templateImgSrc: 'unset',
      templateId: 'unset',
      heightWidthRatio: 0.6,
      artboardHeight: 600,
      artboardWidth: 600, // Math.round(this.state.artboardHeight * this.state.heightWidthRatio),
      regionIndex: 0,
      regionId: 'unset',
      cols: 1,
      rows: 1,
      x: 0,
      y: 0,
      w: 0.1,
      h: 0.1,
      padX: 0.0,
      padY: 0.0,
    };

    this.canvasRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.setDefaults = this.setDefaults.bind(this);
    this.handleLoadedJSON = this.handleLoadedJSON.bind(this);
    this.handleLoadedImage = this.handleLoadedImage.bind(this);
  }

  componentDidMount() {
    console.log('componentDidMount');

    this.canvas = this.canvasRef.current;
    this.ctx = this.canvas.getContext('2d');

    this.canvas.height = this.state.artboardHeight;
    this.canvas.width = this.state.artboardWidth;

    const { search } = window.location;
    const params = new URLSearchParams(search);

    // Gather default settings from query params
    const templateImgSrc = params.get('img');
    const templateId = params.get('template');
    const regionId = params.get('region');

    this.setState({ templateImgSrc, templateId, regionId }, () => {
      // const fileUploaded = new FileReader();
      // fileUploaded.readAsText(MY_JSON_FILE);
      // console.log(JSON.parse(fileUploaded));

      // this.redrawOverlay();
    });
  }

  updateRects() {
    const {
      x, y, w, h, padX, padY, cols, rows, artboardWidth, artboardHeight,
    } = this.state;

    const rectArray = [];
    const coords = {
      x: x * artboardWidth,
      y: y * artboardHeight,
      w: w * artboardWidth,
      h: h * artboardHeight,
      padX: padX * artboardWidth,
      padY: padY * artboardHeight,
    };

    const columnWidth = coords.w / cols;
    const rowHeight = coords.h / rows;

    for (let row = 0; row < rows; row += 1) {
      for (let col = 0; col < cols; col += 1) {
        const rect = {};
        rect.x = coords.x + (col * columnWidth) + coords.padX;
        rect.y = coords.y + (row * rowHeight) + coords.padY;
        rect.w = columnWidth - (coords.padX * 2);
        rect.h = rowHeight - (coords.padY * 2);
        rectArray.push(rect);
      }
    }
    return rectArray;
  }

  sliderInput(sliderId, value) {
    this.setState({ [sliderId]: parseFloat(value) });
    this.redrawOverlay();
  }

  setDefaults(template) {
    console.log('setDefaults');
    console.log(template);

    // TODO: We should alter our state vars
    // so they are an exact match of your template
    // config structure, including the array of regions
    const region = template.regions[0];

    this.setState({ ...region }, () => { this.redrawOverlay(); });
  }

  redrawOverlay() {
    console.log('redrawOverlay');

    const { artboardWidth, artboardHeight } = this.state;

    this.ctx.clearRect(0, 0, artboardWidth, artboardHeight);
    const rectArray = this.updateRects();

    for (let i = 0; i < rectArray.length; i += 1) {
      const rect = rectArray[i];
      const {
        x, y, w, h,
      } = rect;
      this.ctx.beginPath();
      this.ctx.rect(x, y, w, h);
      this.ctx.fillStyle = 'rgba(22,255,44,0.4)';
      this.ctx.strokeStyle = 'rgba(0,255,0,0.85)';
      this.ctx.fill();
      this.ctx.stroke();
    }
  }

  exportTemplateJSON() {
    const {
      cols,
      rows,
      x,
      y,
      w,
      h,
      padX,
      padY,
    } = this.state;

    const formatted = JSON.stringify({
      cols,
      rows,
      x,
      y,
      w,
      h,
      padX,
      padY,
    }, null, 2);

    console.log('=== JSON PASTE ===');
    console.log(formatted);

    return formatted;
  }

  handleFileSelect(evt) {
    console.log('handleFileSelect', evt);

    const { files } = evt.target;

    // Files is a FileList of File objects. List some properties.
    for (let i = 0, f; f = files[i]; i += 1) {
      const reader = new FileReader();

      // Use regular expression to get
      // file extension
      const { name } = f;
      const re = /(?:\.([^.]+))?$/;
      const ext = re.exec(name)[1].toLowerCase();

      console.log(ext);
      if (ext === 'json') {
        reader.onload = this.handleLoadedJSON;
        reader.readAsText(f);
      } else if (ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
        reader.onload = this.handleLoadedImage;
        reader.readAsDataURL(f);
      } else {
        alert(`Unexpected file extension = ${ext} - json, png, jpg allowed.`);
      }
    }
  }

  handleLoadedImage(e) {
    console.log('handleLoadedImage = ', e.target);
    const templateImgSrc = e.target.result;
    this.setState({ templateImgSrc });
  }

  handleLoadedJSON(e) {
    console.log('handleLoadedJSON = ', e.target);

    try {
      const loadedObject = JSON.parse(e.target.result);
      this.setDefaults(loadedObject);
    } catch (error) {
      alert(`Error trying to parse json = ${error}`);
    }
  }


  handleInputChange(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    alert(`Welcome ${this.state.firstName} ${this.state.lastName}!`);
  }

  render() {
    const {
      templateImgSrc,
      templateId,
      heightWidthRatio,
      artboardHeight,
      artboardWidth,
      regionIndex,
      regionId,
      rows,
      cols,
      x,
      y,
      w,
      h,
      padX,
      padY,
    } = this.state;

    return (
      <div className="admin">
        <img src={templateImgSrc} alt="Upload cropped template" style={{ width: artboardWidth, height: artboardHeight }} />
        <canvas id="overlay-canvas" ref={this.canvasRef} />

        <div className="tool-panel">
          <h3>Template Tool</h3>
          <form method="post" onSubmit={this.handleSubmit}>
            <input type="file" id="files" name="files[]" multiple onChange={this.handleFileSelect} />
            <br />
            <br />
            <p>
              Align the green rects with your template regions,
              then copy the output into your template configuration.
            </p>
            <span>
              Template: &nbsp;
              {templateId}
            </span>
            <br />
            <span>
              Region index: &nbsp;
              {regionIndex}
              ,
              {regionId}
            </span>
            <br />
            <span />
            <br />
            <input id="cols" className="slider" type="range" min="1" max="20" step="1" value={cols} onChange={e => this.sliderInput('cols', e.target.value)} />
            <span>
              {' '}
              Cols:
              {cols}
            </span>
            <br />
            <input id="rows" className="slider" type="range" min="1" max="20" step="1" value={rows} onChange={e => this.sliderInput('rows', e.target.value)} />
            <span>
              {' '}
              Rows:
              {rows}
            </span>
            <br />
            <input id="x" className="slider" type="range" min="0.0" max="1.0" step="0.0001" value={x} onChange={e => this.sliderInput('x', e.target.value)} />
            <span>
              {' '}
              X:
              {x}
            </span>
            <br />
            <input id="y" className="slider" type="range" min="0.0" max="1.0" step="0.0001" value={y} onChange={e => this.sliderInput('y', e.target.value)} />
            <span>
              {' '}
              Y:
              {y}
            </span>
            <br />
            <input id="w" className="slider" type="range" min="0.0" max="1.0" step="0.0001" value={w} onChange={e => this.sliderInput('w', e.target.value)} />
            <span>
              {' '}
              W:
              {w}
            </span>
            <br />
            <input id="h" className="slider" type="range" min="0.0" max="1.0" step="0.0001" value={h} onChange={e => this.sliderInput('h', e.target.value)} />
            <span>
              {' '}
              H:
              {h}
            </span>
            <br />
            <input id="padX" className="slider" type="range" min="0.0" max="0.1" step="0.0001" value={padX} onChange={e => this.sliderInput('padX', e.target.value)} />
            <span>
              {' '}
              PadX:
              {padX}
            </span>
            <br />
            <input id="padY" className="slider" type="range" min="0.0" max="0.1" step="0.0001" value={padY} onChange={e => this.sliderInput('padY', e.target.value)} />
            <span>
              {' '}
              PadY:
              {padY}
            </span>
            <br />
            <br />
            <label>
              First name
              <input
                type="text"
                name="firstName"
                value={this.state.firstName}
                onChange={this.handleInputChange}
              />
            </label>
            <label>
            Last name
              <input
                type="text"
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleInputChange}
              />
            </label>
            <button type="submit">Submit</button>
          </form>
          <CopyToClipboard
            text={this.exportTemplateJSON()}
            onCopy={() => this.setState({ copied: true })}
          >
            <button>Copy to clipboard</button>
          </CopyToClipboard>
          {this.state.copied ? <span style={{ color: 'green' }}>&nbsp; Copied.</span> : null}
        </div>
      </div>
    );
  }
}
